/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* RESPONSIBLE TEAM: team-reporting */
import containerLookup from 'embercom/lib/container-lookup';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { type MetricPropertyDefinition } from 'embercom/objects/reporting/unified/properties/types';
import { camelize } from '@ember/string';
import {
  ADMIN_STATUS_CHANGE_SOURCE,
  CONVERSATION_STATE_DATASET,
} from 'embercom/lib/reporting/custom/object-catalog';

import {
  CONVERSATION_ATTRIBUTES,
  CUSTOM_COMPANY_ATTRIBUTE,
  CUSTOM_USER_ATTRIBUTE,
  FILTERABLE_CONVERSATION_CUSTOM_ATTRIBUTE_TYPES,
  FILTERABLE_USER_AND_COMPANY_ATTRIBUTE_TYPES,
  SYSTEM_DEFINED_ATTRIBUTES,
  TICKETS_ATTRIBUTES,
} from 'embercom/objects/reporting/unified/datasets/types';

import { isPresent } from '@ember/utils';
import { flatten } from 'underscore';
import { type Attribute } from 'embercom/objects/reporting/unified/datasets/types';
import type ReportingStandalone from 'embercom/services/reporting-standalone';

export type Aggregation =
  | 'count'
  | 'cardinality'
  | 'min'
  | 'max'
  | 'mean'
  | 'median'
  | 'scripted'
  | 'sum'
  | 'percentile'
  | 'range';
export type Unit = 'value' | 'seconds' | 'percent' | 'valuePerHour';
type ImprovementDirection = 'increasing' | 'decreasing';
type ModelName = 'conversation' | 'ticket' | 'teammate' | 'copilot_prompt_response_pair';
type RangeFilterType = 'gt' | 'gte' | 'lt' | 'lte';
type DateRangeFilterType = 'date_range';
type Group = 'conversation' | 'ticket' | 'sla' | 'copilot' | 'tickets' | 'ticket_time_in_state';
type ReleaseStatus = 'beta';
type Icon = 'count' | 'sla' | 'decimal' | 'percentile';

export type Filter = AndFilter | CategoryFilter | RangeFilter | DateRangeFilter | OrFilter;
export type MetricTypeName = 'field' | 'percentage' | 'ratio';

export const NO_DATASET = 'no_dataset';
export const METRIC_UNIT_MAP = {
  ['value']: 'value',
  ['valuePerHour']: 'value',
  ['seconds']: 'seconds',
  ['percent']: 'percent',
};

export const METRIC_IDS_WITH_SHOW_DENOMINATOR_WITH_NUMERATOR_FILTERS = [
  'conversation_rating.human.csat',
  'conversation_rating.ai_agent.csat',
  'conversation_rating.any_agent.csat',
  'conversation_rating.workflows.csat',
  'conversation_rating.human.csat.first_user_conversation_part_created_at',
  'conversation_rating.human.dsat',
  'conversation_rating.ai_agent.dsat',
  'conversation_rating.any_agent.dsat',
  'conversation_rating.workflows.dsat',
  'conversation.ai_generated_metrics.csat_score',
  'conversation.ai_generated_metrics.dsat_score',
];

interface AndFilter {
  type: 'and';
  filters: Filter[];
}

interface OrFilter {
  type: 'or';
  filters: Filter[];
}

interface CategoryFilter {
  type: 'category';
  data: {
    property: string;
    values: string[];
  };
}

export interface FilterItem {
  text: string;
  value: string;
  isSelected: boolean;
  icon: string;
  type?: string;
  ticketType?: string;
  category?: string;
  headerName?: string;
  group?: string | null;
}

interface RangeFilter {
  type: RangeFilterType;
  data: {
    property: string;
    values: string[];
  };
}

interface DateRangeFilter {
  type: DateRangeFilterType;
  data: {
    attribute: string;
    property: string;
    values: [number, number];
  };
}

interface Migration {
  replacement_metric_id: string;
  attribute_id_mappings?: {};
  aggregation: string | null;
  feature_flag: string;
}

interface BaseMetricDefinition {
  id: string;
  type: MetricTypeName;
  name: string;
  model?: ModelName;
  description: string | null;
  valueDescription?: string;
  unit: Unit;
  dataPointFormat?: string;
  improvementDirection: ImprovementDirection;
  suggestedMetricId?: string;
  group?: Group;
  releaseStatus?: ReleaseStatus;
  qualifiers?: string[];
  icon?: Icon;
  teammateProperty?: string;
  teammateIdDescription?: string;
  teammateProperties?: object;
  teamProperty?: string;
  metricFamilyId?: string;
  metricFamilyDefault?: boolean;
  metricVariantType?: string;
  suggestedAttributeIds?: string[];
  availableInCustomReports?: boolean;
  datasetId: string;
  migration?: Migration;
  deprecated?: boolean;
}

interface RatioMetricDefinition extends BaseMetricDefinition {
  type: 'ratio';
  unit: Unit;
  numeratorMetricId: string;
  denominatorMetricId: string;
}

interface PercentageMetricDefinition extends BaseMetricDefinition {
  type: 'percentage';
  unit: 'percent';
  numeratorMetricId: string;
  denominatorMetricId: string;
}

interface FieldMetricDefinition extends BaseMetricDefinition {
  type: 'field';
  source: string;
  property: string;
  teamProperty?: string;
  supportedAggregations: Aggregation[];
  timeProperty: string;
  filter?: Filter;
}

type MetricDefinition = FieldMetricDefinition | PercentageMetricDefinition | RatioMetricDefinition;

export default abstract class Metric implements BaseMetricDefinition {
  readonly id: string;
  readonly type: MetricTypeName;
  readonly name: string;
  readonly model?: ModelName;
  readonly description: string | null;
  readonly valueDescription?: string;
  readonly unit: Unit;
  readonly dataPointFormat?: string;
  readonly improvementDirection: ImprovementDirection;
  readonly suggestedMetricId?: string;
  readonly group?: Group;
  readonly releaseStatus?: ReleaseStatus;
  readonly qualifiers?: string[];
  readonly icon?: Icon;
  readonly teammateProperty?: string;
  readonly teammateIdDescription?: string;
  readonly metricFamilyId?: string;
  readonly metricFamilyDefault?: boolean;
  readonly metricVariantType?: string;
  readonly teammateProperties?: object;
  readonly teamProperty?: string;
  readonly suggestedAttributeIds: string[];
  readonly availableInCustomReports: boolean;
  readonly datasetId: string;
  readonly migration?: Migration;
  readonly deprecated?: boolean;

  abstract get allFieldMetrics(): FieldMetric[];
  abstract get timeProperty(): string;

  constructor(metricDefinition: MetricDefinition) {
    this.id = metricDefinition.id;
    this.type = metricDefinition.type;
    this.name = metricDefinition.name;
    this.model = metricDefinition.model;
    this.description = metricDefinition.description;
    this.valueDescription = metricDefinition.valueDescription;
    this.unit = metricDefinition.unit;
    this.dataPointFormat = metricDefinition.dataPointFormat;
    this.improvementDirection = metricDefinition.improvementDirection;
    this.suggestedMetricId = metricDefinition.suggestedMetricId;
    this.group = metricDefinition.group ?? 'conversation';
    this.releaseStatus = metricDefinition.releaseStatus;
    this.qualifiers = metricDefinition.qualifiers;
    this.icon = metricDefinition.icon;
    this.teammateProperty = metricDefinition.teammateProperty;
    this.teammateIdDescription = metricDefinition.teammateIdDescription;
    this.teamProperty = metricDefinition.teamProperty;
    this.metricFamilyId = metricDefinition.metricFamilyId;
    this.metricFamilyDefault = metricDefinition.metricFamilyDefault ?? false;
    this.metricVariantType = metricDefinition.metricVariantType;
    this.teammateProperties = metricDefinition.teammateProperties;
    this.suggestedAttributeIds = metricDefinition.suggestedAttributeIds || [];
    this.availableInCustomReports = metricDefinition.availableInCustomReports ?? false;
    this.datasetId = metricDefinition.datasetId;
    this.migration = metricDefinition.migration;
    this.deprecated = metricDefinition.deprecated;
  }

  static fromDefinition(metricDefinition: MetricDefinition): Metric {
    if (metricDefinition.type === 'field') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return new FieldMetric(metricDefinition);
    } else if (metricDefinition.type === 'percentage') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return new PercentageMetric(metricDefinition);
    } else if (metricDefinition.type === 'ratio') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return new RatioMetric(metricDefinition);
    }

    throw new Error(`Unknown metric definition type`);
  }

  static fromSnakeCaseDefinition(metricDefinition: any): Metric {
    return Metric.fromDefinition(Metric.camelizeKeys(metricDefinition));
  }

  static camelizeKeys(hash: any): any {
    let entries = Object.entries(hash);
    let camelizedEntries = entries.map(([key, value]) => [camelize(key), value]);
    return Object.fromEntries(camelizedEntries);
  }

  get isTicketMetric(): boolean {
    return (
      this.group === 'ticket' || this.group === 'tickets' || this.group === 'ticket_time_in_state'
    );
  }

  get supportedFilters(): Attribute[] {
    return this.supportedMetricProperties.filter((attribute) =>
      this.isFilterableAttribute(attribute),
    );
  }

  get supportedFiltersExcludeTimeAttribute(): Attribute[] {
    return this.getMetricAttributes(false).filter((attribute) =>
      this.isFilterableAttribute(attribute),
    );
  }

  get supportedBreakdowns(): Attribute[] {
    return this.breakdownableAttributesForDataset;
  }

  get supportedMetricProperties(): Attribute[] {
    return this.getMetricAttributes(true);
  }

  getSuggestedAttributeIds(isStandalone = false) {
    if (isStandalone && isPresent(this.suggestedAttributeIds)) {
      return this.reportingStandalone.filterSuggestedAttributes(this.suggestedAttributeIds);
    } else if (this.isDurationMetric) {
      return [...this.suggestedAttributeIds, this.reportingMetrics.bucketAttribute.id];
    } else {
      return this.suggestedAttributeIds;
    }
  }

  get isDurationMetric() {
    return this.unit === 'seconds';
  }

  getMetricAttributes(includeTimeAttribute: boolean): Attribute[] {
    let filterableAttributes = this.reportingMetrics
      .getDatasetAttributesFor(this.datasetId)
      .reject((attribute) => this.isCdaAttribute(attribute));
    if (includeTimeAttribute) {
      filterableAttributes.push(this.reportingMetrics.timeAttribute);
    }

    return flatten([
      filterableAttributes,
      this.reportingMetrics.getFilterableDatasetCdaAttributesFor(this.datasetId),
    ]).compact();
  }

  get timeAttribute(): Attribute | undefined {
    // TODO replace this with this.reportingMetrics.getAttributeById(this.timeAttributeId), once we have timeAttributeId in the metric definition
    return this.reportingMetrics.getAttributeByField(this.timeProperty, this.datasetId);
  }

  private isCdaAttribute(attribute: Attribute) {
    if (attribute.group) {
      return [
        CUSTOM_COMPANY_ATTRIBUTE,
        CUSTOM_USER_ATTRIBUTE,
        TICKETS_ATTRIBUTES,
        SYSTEM_DEFINED_ATTRIBUTES,
        CONVERSATION_ATTRIBUTES,
      ].includes(attribute.group);
    }
    return false;
  }
  private isFilterableAttribute(attribute: MetricPropertyDefinition) {
    return attribute.supportsFiltering;
  }

  getPropertyById(id: string): MetricPropertyDefinition {
    return this.reportingMetrics.getPropertyById(id);
  }

  get reportingMetrics(): ReportingMetrics {
    return containerLookup('service:reportingMetrics');
  }

  get reportingStandalone(): ReportingStandalone {
    return containerLookup('service:reporting-standalone');
  }

  get app() {
    return containerLookup('service:appService')?.app;
  }

  get attributesForDataset(): Attribute[] {
    return this.reportingMetrics.getDatasetAttributesFor(this.datasetId);
  }

  isSupportedConversationCdaAttribute(cdaType: string | null): boolean {
    if (cdaType) {
      return FILTERABLE_CONVERSATION_CUSTOM_ATTRIBUTE_TYPES.includes(cdaType);
    }
    return false;
  }

  isSupportedUserCompanyCdaAttribute(cdaType: string | null): boolean {
    if (cdaType) {
      return FILTERABLE_USER_AND_COMPANY_ATTRIBUTE_TYPES.includes(cdaType);
    }
    return false;
  }

  get breakdownableAttributesForDataset(): Attribute[] {
    let filterableNonCdaAttributes = flatten([
      this.reportingMetrics
        .getDatasetAttributesFor(this.datasetId)
        .reject((attribute) => this.isCdaAttribute(attribute))
        .filter((attribute: Attribute) => attribute.supportsBreakdown && !attribute.variantOf),
      this.reportingMetrics.timeAttribute,
      this.isDurationMetric ? this.reportingMetrics.bucketAttribute : null,
    ]).compact();

    let attributeFieldIds = flatten([
      filterableNonCdaAttributes,
      this.reportingMetrics.getGroupableDatasetCdaAttributesFor(this.datasetId),
    ]);

    if (this.type === 'ratio') {
      return attributeFieldIds.filter((attr) =>
        [
          'time',
          'conversation_part.teammate_id',
          'conversation_part.teammate_assignee_at_close',
        ].includes(attr.id),
      );
    }
    return attributeFieldIds.compact();
  }

  get firstSource(): string {
    return this.allFieldMetrics[0].source;
  }

  get supportsRelativeValues(): boolean {
    return this.unit === 'value' && this.type === 'field';
  }
}

class FieldMetric extends Metric implements FieldMetricDefinition {
  readonly type = 'field';
  readonly source: string;
  readonly property: string;
  readonly teamProperty?: string;
  readonly supportedAggregations: Aggregation[];
  private _timeProperty: string;
  readonly filter?: Filter | undefined;

  constructor(metricDefinition: FieldMetricDefinition) {
    super(metricDefinition);
    this.source = metricDefinition.source;
    this.property = metricDefinition.property;
    this.teamProperty = metricDefinition.teamProperty;
    this.supportedAggregations = metricDefinition.supportedAggregations;
    this._timeProperty = metricDefinition.timeProperty;
    this.filter = metricDefinition.filter;
  }

  get suggestedMetric(): Metric | null {
    if (this.suggestedMetricId) {
      return this.reportingMetrics.getMetricById(this.suggestedMetricId);
    }
    return null;
  }

  get defaultAggregation(): Aggregation {
    return this.supportedAggregations[0];
  }

  get allFieldMetrics(): FieldMetric[] {
    return [this];
  }

  get supportsHeatmaps(): boolean {
    return (
      this.source !== ADMIN_STATUS_CHANGE_SOURCE && this.datasetId !== CONVERSATION_STATE_DATASET
    );
  }

  get timeProperty() {
    return this._timeProperty;
  }

  get attribute(): Attribute | undefined {
    // TODO replace this with this.reportingMetrics.getAttributeById(this.attributeId), once we have attributeId in the metric definition
    return this.reportingMetrics.getAttributeByField(this.property, this.datasetId);
  }
}

class RatioMetric extends Metric implements RatioMetricDefinition {
  readonly type = 'ratio';
  readonly unit: Unit;
  readonly numeratorMetricId: string;
  readonly denominatorMetricId: string;

  constructor(metricDefinition: RatioMetricDefinition) {
    super(metricDefinition);
    this.numeratorMetricId = metricDefinition.numeratorMetricId;
    this.denominatorMetricId = metricDefinition.denominatorMetricId;
    this.unit = metricDefinition.unit;
  }

  get numerator(): FieldMetric {
    return this.reportingMetrics.getMetricById(this.numeratorMetricId) as FieldMetric;
  }

  get denominator(): FieldMetric {
    return this.reportingMetrics.getMetricById(this.denominatorMetricId) as FieldMetric;
  }

  get allFieldMetrics(): FieldMetric[] {
    return [this.numerator, this.denominator];
  }

  get filter(): Filter | undefined {
    return this.numerator.filter;
  }

  get property(): string {
    return this.numerator.property;
  }

  get timeProperty(): string {
    return this.numerator.timeProperty;
  }

  get supportsHeatmaps(): boolean {
    return this.allFieldMetrics.every((metric) => metric.supportsHeatmaps);
  }
}

export class PercentageMetric extends Metric implements PercentageMetricDefinition {
  readonly type = 'percentage';
  readonly unit = 'percent';
  readonly numeratorMetricId: string;
  readonly denominatorMetricId: string;

  constructor(metricDefinition: PercentageMetricDefinition) {
    super(metricDefinition);
    this.numeratorMetricId = metricDefinition.numeratorMetricId;
    this.denominatorMetricId = metricDefinition.denominatorMetricId;
  }

  get showDenominatorWithNumeratorFilters(): boolean {
    // When this is true, drilling into a chart with one of the defined metrics will show
    // the denominator metric with the numerator filter in the filter panel.
    // This enables better contextual information when drilling into a chart.
    // For example, for CSAT metrics, instead of just seeing positive conversations,
    // we now see all conversations with the numerator filter applied (e.g. "Conversation rating: Great, Positive") so
    // customers can change that to see negatively rated conversations.
    // To enable this for other metrics, the attribute_id needs to be defined on the metric's filter definition.
    // Example pr: https://github.com/intercom/intercom/pull/366652
    return METRIC_IDS_WITH_SHOW_DENOMINATOR_WITH_NUMERATOR_FILTERS.includes(this.id);
  }

  get numerator(): FieldMetric {
    return this.reportingMetrics.getMetricById(this.numeratorMetricId) as FieldMetric;
  }

  get denominator(): FieldMetric {
    return this.reportingMetrics.getMetricById(this.denominatorMetricId) as FieldMetric;
  }

  get allFieldMetrics(): FieldMetric[] {
    return [this.numerator, this.denominator];
  }

  get filter(): Filter | undefined {
    return this.numerator.filter;
  }

  get property(): string {
    return this.numerator.property;
  }

  get timeProperty(): string {
    return this.numerator.timeProperty;
  }

  get supportsHeatmaps(): boolean {
    return this.allFieldMetrics.every((metric) => metric.supportsHeatmaps);
  }
}

export { Metric, FieldMetric, RatioMetric, MetricDefinition };
